import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/login'
import {getLoggedInUser} from '@Appmart/utility'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'Home',
    component: Home
  }
]

const router = new VueRouter({
  // mode: "history",
  routes
})

export default router
