// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/design.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Proxima+Nova);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main-wrap[data-v-6c1cd837]{background:#e5e5e5;margin-bottom:80px}.wrapper[data-v-6c1cd837]{width:100%}.arrow[data-v-6c1cd837]{left:6.25%;right:6.24%;top:21.87%;bottom:21.87%}.ellipse9[data-v-6c1cd837]{width:530px;height:533px}.ellipse9[data-v-6c1cd837],.ellipse10[data-v-6c1cd837]{background:#0c4f6c;box-shadow:0 0 47px hsla(0,0%,100%,0)}.ellipse10[data-v-6c1cd837]{width:436px;height:436px}.img[data-v-6c1cd837]{height:100%!important;width:106%;-o-object-fit:cover;object-fit:cover}.frame[data-v-6c1cd837]{width:103%;background:#e5e5e5}.side-bar__text[data-v-6c1cd837]{color:#fff;font-size:4rem;font-weight:700;transform:translate(-50%,-50%);z-index:100}.main_txt[data-v-6c1cd837]{font-weight:700;color:#4f4f4f;font-family:Proxima Nova;font-style:normal;font-weight:600;font-size:42px;line-height:110%}.sidebar[data-v-6c1cd837]{background-color:#f5f5f5!important;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat}@media (max-width:1200px){.side-bar__text[data-v-6c1cd837]{font-size:3rem}}@media (max-width:750px){.sidebar[data-v-6c1cd837]{display:none;background-color:red}.container-left[data-v-6c1cd837]{width:100%!important}.wrapper[data-v-6c1cd837]{display:flex;justify-content:center;align-items:center;height:100%;padding:3rem}}", ""]);
// Exports
module.exports = exports;
