import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

/// define the endpoint to use
Vue.prototype.$apiUrl = 
Vue.config.productionTip = false;
Vue.config.productionTip = false;
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        otherProp: this.otherProp,
      //   props: {
      //     customProps:customProps
      //     // single-spa props are available on the "this" object. Forward them to your component as needed.
      //     // https://single-spa.js.org/docs/building-applications#lifecyle-props
      //     // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
      //     /*
      //     name: this.name,
      //     mountParcel: this.mountParcel,
      //     singleSpa: this.singleSpa,
      //     */
      //   },
      // }
      }
      );
    },
    router,
    store,
    vuetify
  },
});
// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
