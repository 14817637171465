<template>
<!-- <div class="main-wrap" style="height: 100vh;"> -->
<div>
    <div class="row" style="height: 100vh;">
        
        <div class="col-md-7" style="background:#f5f5f5">
            <div style="padding:2%">
                <div class="card col-md-6 pa-4 " style="margin:auto; margin-top:20%; border:0">
                    <div>
                    </div>
                    <div style="font-size:26px; color:#666; text-align:center; margin:0 0 20px 0">Login to your dashboard {{otherProp}}</div>
                    <!-- <b-form @submit.stop.prevent> -->
                    <v-text-field label="UserID" placeholder="UserID" outlined dense v-model="userId"></v-text-field>
                    <v-text-field label="Password" placeholder="Password" outlined type="password" dense v-model="password"></v-text-field>
                    <!-- <b-form-input class="py-2" placeholder="User ID" v-model="userId" id="feedback-user"></b-form-input> -->
                    <!-- <b-form-input class="py-2 mt-7 mb-2" type="password" v-model="password" id="text-password" placeholder="Password" aria-describedby="password-help-block"></b-form-input> -->
                    <!-- </b-form> -->
                    <router-link to="/forgotpassword" style="text-decoration: none; color: gray;">
                        <p class="text-right" style="font-size:12px">
                            <strong>Forgot Password?</strong>
                        </p>
                    </router-link>
                    <button block squared @click="submit()" style="background-color:#0C4D6A;color:white;padding:10px">
                        <strong>Login
                            <b-icon class="ml-6" icon="arrow-right"></b-icon>
                        </strong>
                    </button>
                </div>
                <div style="width: 100%">
                    <!-- <img class="frame" src="../assets/frame.png" alt="Image here"> -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css"
import Swal from 'sweetalert2';
// import axios from 'axios'
import router from '@/router/index'
// import {parseJwt,ShowActivityLoader} from '@Appmart/utility'

export default {
      props: ['otherProp'],
    data: () => ({
        userId: '',
        password: '',
    }),
    computed: {
        validation() {
            return this.userId.length > 4 && this.userId.length < 13
        }
    },
mounted(){
  //console.log('user', getLoggedInUser());
},

    methods: {
         getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
},
ShowActivityLoader() {    
  if (document.querySelector("#pleaseWaitDialog") == null) {
      var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
          <div class="modal-dialog" style="background-color:transparent;border:none">\
              <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
                  <div class="modal-body">\
                      <div class="spinner-border" role="status" style="color:#fff">\
  <span class="sr-only"></span>\
</div>\
<div style="color:#fff">Loading ...</div>\
                  </div>\
              </div>\
          </div>\
      </div>';
      $(document.body).append(modalLoading);
  }

  $("#pleaseWaitDialog").modal("show");
},
hideActivityLoader() {
  $("#pleaseWaitDialog").modal("hide");
},

parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
},
        submit() {
            this.ShowActivityLoader()
            if (this.userId == '' || this.password == '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Empty Form',
                    text: 'Fill the Form',
                })
            } else {
                const data = {
                    email: this.userId,
                    password: this.password,
                    app_name: 'MyUnizik',
                    channelType: 'custom'
                };
                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');

                fetch('https://mycp.unizik.edu.ng/v1/admin/adminlogin', {
                    method: 'POST',
                    redirect: 'follow',
                    credentials: 'include', // Don't forget to specify this if you need cookies
                    headers: headers,
                    body: JSON.stringify(data)
                }).then(async response => {
                        const result = await response.json();
                        // const result = response.data
                        console.log(result) // this.$router.push('/login')
                        if (result.code != '00') {
                            Swal.fire({
                                icon: 'error',
                                title: 'Login Error',
                                text: result.message,
                            })
                        } else {
                            //////// use javascript to set the cookie//// i am doind this so i can use a parcel for it latter to read and write cookies
                            let d = new Date();
                            d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                            let expires = "expires=" + d.toUTCString();
                            document.cookie = "-X_Zka=" + result.message + ";" + expires + ";path=/";
const decoded = this.parseJwt(result.message)
                            router.push({
                                path: decoded.default_menu
                            })
                        }
                    }

                    //     response => {
                    //     console.log(response.data)
                    //                         const result = response.data
                    //                         console.log(result) // this.$router.push('/login')
                    //                         if (result.code != '00')
                    //                             Swal.fire({
                    //                                 icon: 'error',
                    //                                 title: 'Login Error',
                    //                                 text: result.message,
                    //                             })
                    // else
                    // router.push({
                    //                                 path: 'dashboard'
                    //                             })
                )
                //             axios
                //                 .post('http://localhost:5003/v1/admin/adminlogin', data,{
                //     withCredentials: true,
                //     credentials: 'include',
                //   })
                //                 .then(response => {
                //                     const result = response.data
                //                     console.log(result) // this.$router.push('/login')
                //                     if (result.code != '00')
                //                         Swal.fire({
                //                             icon: 'error',
                //                             title: 'Login Error',
                //                             text: result.message,
                //                         })
                //                     else

                //                         router.push({
                //                             path: 'dashboard'
                //                         })
                //                 })

            }
        },
    },
}
</script>

<style scoped src="bootstrap/dist/css/bootstrap.css"></style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

.main-wrap {
    background: #E5E5E5;
    margin-bottom: 80px;
}

.wrapper {
    width: 100%;
}

.arrow {
    left: 6.25%;
    right: 6.24%;
    top: 21.87%;
    bottom: 21.87%;
}

.ellipse9 {
    width: 530px;
    height: 533px;
    background: #0C4F6C;
    box-shadow: 0px 0px 47px rgba(255, 255, 255, 0.0);
}

.ellipse10 {
    width: 436px;
    height: 436px;
    background: #0C4F6C;
    box-shadow: 0px 0px 47px rgba(255, 255, 255, 0.0);
}

.img {
    height: 100% !important;
    width: 106%;
    object-fit: cover;
}

.frame {
    /* height: 100% !important; */
    width: 103%;
    background: #E5E5E5;
}

.side-bar__text {
    color: #fff;
    font-size: 4rem;
    font-weight: bold;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.main_txt {
    font-weight: bold;
    color: #4F4F4F;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 110%;
}

.sidebar {
    background-color: #f5f5f5 !important;
    background: url("../assets/design.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

@media (max-width: 1200px) {
    .side-bar__text {
        font-size: 3rem;
    }

}

@media (max-width: 750px) {
    .sidebar {
        display: none;
        background-color: red;

    }

    .container-left {
        width: 100% !important;
    }

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 3rem;
    }
}
</style>
